.sticky-footer {
  clear: both;
  position: relative;
  justify-content: center;
  bottom: 0;
  height: 42px;
  background-color: rgb(15, 15, 15);
  margin-top: -42px;
  z-index: 4;
  width: 100%;
  p {
    padding: 10px;
    margin: 0;
  }
}
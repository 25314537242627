@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
}

body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  font-family: Roboto -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ColoredSquare { 
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 8px;
  border-radius: 4px;
  background: #008561;
//   background: ${({ color }: {color: any}) => color};
};

.visx-tooltip {
  z-index: 8;
}

.visx-tooltip-glyph {
  svg {
    width: 50px;
    height: 50px;
  }
}

.TooltipContainer {
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 4px;
  color: #222222;

  .date {
    font-size: 12px;
    margin-bottom: 8px;
    color: #222222;
    font-weight: 600;
  }
  .value {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #000000;
  }
}